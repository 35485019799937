import React, { useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
// react components for routing our app without refresh

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";
import Button from "components/CustomButtons/Button.js";
import { cardTitle } from "assets/jss/material-kit-pro-react.js";
import sectionPillsStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionPillsStyle.js";
const style = {
  ...imagesStyles,
  cardTitle,
  sectionPillsStyle,
};

const useStyles = makeStyles(style);

const VIDEOS = [
  {
    index: 1,
    link: "https://www.youtube.com/embed/eYvdFtbaOQ4?si=jGLvNyYOETtxFb-V",
    title: "Academic writing, Ontology and Epistemology",
    aboutParagraphs: [
      "Original Contribution to knowledge: What exactly does this mean?",
    ],
  },
  {
    index: 2,
    link: "https://www.youtube.com/embed/5smw6n3aobE?si=RYPdaelifnqRE5Jj",
    title: "Part 1: Worldviews and Epistemology",
    aboutParagraphs: [
      "What is Epistemology? The theory of knowledge, especially with regard to its methods, validity and scope, and the distinction between justified belief and opinion.",
    ],
  },
  {
    index: 3,
    link: "https://www.youtube.com/embed/0wHWbAosAg0?si=Mi8Nnw2_eDxnAuKd",
    title: "Part 2: Worldviews and Epistemology",
    aboutParagraphs: [
      "Types of Epistemology? Positivist epistemology and Phenomenological epistemology",
    ],
  },
  {
    index: 4,
    link: "https://www.youtube.com/embed/Ow7r8oJ6wsM?si=6Ig0FePzTGnEtsMs",
    title: "Part 3: Worldviews and Epistemology",
    aboutParagraphs: [
      "Your personal understanding who God is count. Your values count. Your participants values count.",
    ],
  },
  {
    index: 5,
    link: "https://www.youtube.com/embed/_zv6Q4p6C6Y?si=HPn_Ew4ioWxQGrov",
    title: "Part 4: Worldviews and Epistemology",
    aboutParagraphs: [
      "What influences the decisions on what epistomology you adopt.",
      "First you need clarity in what research questions you want to ask and what theory you want to expand on then you can decide about epistemology.",
    ],
  },
  {
    index: 6,
    link: "https://www.youtube.com/embed/Gomj4VNMVHI?si=ocQEg-t9li_Ppvc9",
    title: "Positivism Epistemology",
    aboutParagraphs: [
      "Positivism epistemology involves framing hypothesis that you intend to prove and validate",
      "Is your hypothesis assertions supported by fellow scholars?",
      "The strengths and weaknesses of taking potivism epistemology stance",
      "What is strong your position",
    ],
  },
  {
    index: 7,
    link: "https://www.youtube.com/embed/R2VY4gtJt8o?si=S05YqygwbJjUjJGy",
    title: "Phenomenology epistemology",
    aboutParagraphs: [
      "Phenomenology is the study of the structure and essense of consciousness",
    ],
  },
  {
    index: 8,
    link: "https://www.youtube.com/embed/bLM2WJGnGAc?si=Ljo0F74mc2zPT5D5",
    title: "Phenomenology",
    aboutParagraphs: [
      "Be aware of how your own natural attitude, your own reality, your own experiences about the phenomenon you are looking at how they shape the review of responses from your participants.",
      "What do you document?  How do you document the data you received from research participates? Using your sensory modalities: what you’ve heard, seen, smelled, sense and your intuition. ",
    ],
  },
  {
    index: 9,
    link: "https://www.youtube.com/embed/sRmAt5aph4E?si=9AxkeLJn_YHztTv9",
    title: "What is your ontological position?",
    aboutParagraphs: [
      "Ontological issues in research, why it important and how it fit into your research project.",
      "Your research must speak to you, speak to your soul, must speak to your belief system and must speak to your passion.",
    ],
  },
  {
    index: 10,
    link: "https://www.youtube.com/embed/CGlCrsiw4XU?si=E56izYwIlxBi0dd-",
    title: "How to contribute to original knowledge",
    aboutParagraphs: [
      "Introducing fresh insight to existing theory with durable impact.",
      "How to demonstrate original contribution to knowledge.",
    ],
  },
  {
    index: 11,
    link: "https://www.youtube.com/embed/zaA0RYOS1Xw?si=yC1VCBzARVzd8gOE",
    title:
      "Theoretical framework of theory, literature review, and academic rhetoric",
    aboutParagraphs: [
      "Thesis three Golden threads:",
      "1. Your research Question",
      "2. Your theoretical Lens",
      "3. Your world view and position: Epistemology and your ontologival view",
    ],
  },
  {
    index: 12,
    link: "https://www.youtube.com/embed/MV3X0VqMAU8?si=xDo7AyzeYBJy8AR8",
    title: "Research Topic",
    aboutParagraphs: [
      "Naming your research is like naming a child.",
      "Immerse yourself in literature to have depth understanding of the state of knowledge around your research topic.",
      "How to come up with a different angle to standout and giving fresh perspectives.",
    ],
  },
  {
    index: 13,
    link: "https://www.youtube.com/embed/tpSXjSxFbXE?si=X8wNZdysesFUZi3w",
    title: "Writing up your theoretical framework",
    aboutParagraphs: [
      "Let's delve deep into the art of crafting a literature review and figuring out whether the literature review or theoretical framework comes first",
    ],
  },
  {
    index: 14,
    link: "https://www.youtube.com/embed/0JNP1nbDT4M?si=9VmnUxKI1CnT7N26",
    title: "Tips on Writing Your Theoretical Framework",
    aboutParagraphs: [
      "How prove your contribution to knowldege/theory",
      "How show mastery in the theory focusing on the building blocks",
      "It's takes time, It's takes alot of reading, you start off with a few questions you narrow them down you refine them as you read.",
    ],
  },
  {
    index: 15,
    link: "https://www.youtube.com/embed/rG7Ir-gqt2w?si=vOw05M7Sj0R3JEw_",
    title: "Part 1: Research Questions",
    aboutParagraphs: [
      "Research Questions is the DNA of your research because everything you be doing throughout is tied back to responding to your research question.",
      "Be open minded do not be married to your research question.",
      "Research questions what?, how?, where?, why? Do not ask questions that can be responded to without doing any further interrogation.",
    ],
  },
  {
    index: 16,
    link: "https://www.youtube.com/embed/rG7Ir-gqt2w?si=vOw05M7Sj0R3JEw_",
    title: "Part 2: Research Questions",
    aboutParagraphs: [
      "What guides the research questions?",
      "How many research questions should we have?",
      "What is the difference between a research question and an interview question?",
    ],
  },
  {
    index: 17,
    link: "https://www.youtube.com/embed/1CHCp4H_OCc?si=uSy4yEEaqXeEIC_t",
    title: "Problematising your Question",
    aboutParagraphs: [
      "We will examine the process of problematising your topic and explore different methodologies",
      "This addresses a question that was brought up during last week's session about creating a research space within a practical problem",
    ],
  },
  {
    index: 18,
    link: "https://www.youtube.com/embed/HtVliKFAn8k?si=HUfCEILHw7NuHHS0",
    title: "Research Design Part 1",
    aboutParagraphs: [
      "Our exploration of the various ingredients that need to be incorporated into the melting pot as we design our research",
    ],
  },
  {
    index: 19,
    link: "https://www.youtube.com/embed/4SZ0qF_wzJ4?si=UvWomvgw75ysBrrw",
    title: "Research Design Part 2",
    aboutParagraphs: [
      "we will explore five distinct categories of research design. These categories encompass explanatory, descriptive, diagnostic, correlational, and experimental research design.",
    ],
  },
  {
    index: 20,
    link: "https://www.youtube.com/embed/RbSqfGneiRE?si=IBVeh7Zb5qChWyKe",
    title: "Research Design Part 3",
    aboutParagraphs: [
      "We dive into the fascinating world of Descriptive Research Design.  A design that seeks to provide a precise and methodical description of a population, sample, or phenomenon.",
    ],
  },
  {
    index: 20,
    link: "https://www.youtube.com/embed/TxV-ZX-ckd4?si=bXhiGp-ODg9Z0tBx",
    title: "Research Design Part 3 continues",
    aboutParagraphs: [
      "We dive into the fascinating world of Descriptive Research Design.  A design that seeks to provide a precise and methodical description of a population, sample, or phenomenon.",
    ],
  },
  {
    index: 21,
    link: "https://www.youtube.com/embed/1GtBkpazN5E?si=k5YLwp7ZxV-9rQ0f",
    title: "Diagnostic Research Design",
    aboutParagraphs: [
      "Diagnostic research design, which aims to identify the underlying reasons of a particular issue or phenomenon. ",
      "It therefore seeks to understand the factors that causes a particular issue and in certain cases, even the validity of the tools being used to understand or study a particular phenomenon. ",
    ],
  },
  {
    index: 22,
    link: "https://www.youtube.com/embed/cY1tQOKXc0Y?si=-XnXbcZUGJ0n73Hr",
    title: "Research Design Part 5 Experimental",
    aboutParagraphs: [
      "Experimental Research Design is employed when examining two sets of variables, with one remaining constant throughout the study. ",
    ],
  },
];

export default function SectionPills() {
  const classes = useStyles();

  const [lectureVideos, setLectureVideos] = useState(VIDEOS);
  const [isDescending, setIsDescending] = useState(false);

  let sortBtnText = isDescending ? "Ascending Order" : "Descending Order";

  function handleSort(order) {
    if (order === "Descending Order") {
      let reverseArray = [...VIDEOS].reverse();
      setLectureVideos(reverseArray);
      setIsDescending(true);
    } else {
      setLectureVideos(VIDEOS);
      setIsDescending(false);
    }
  }

  return (
    <div className={classes.section}>
      <Button type="button" onClick={() => handleSort(sortBtnText)}>
        {sortBtnText}
      </Button>

      <GridContainer>
        {lectureVideos.map((video) => (
          <GridItem xs={12} sm={6} md={6} className={classes.mlAuto}>
            <Card>
              <iframe
                height="315"
                src={video.link}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen="true"
              ></iframe>
              <CardBody>
                <h4 className={classes.cardTitle}>{video.title}</h4>
                {video.aboutParagraphs.map((paragraph) => (
                  <p>{paragraph}</p>
                ))}
              </CardBody>
            </Card>
          </GridItem>
        ))}

        <GridItem xs={12} sm={6} md={6}></GridItem>

        <GridItem xs={12} sm={6} md={6}></GridItem>
      </GridContainer>
    </div>
  );
}
