import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/core icons
import Person from "@material-ui/icons/Person";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import analytics from "assets/img/scientificDiagrams/bigDataAnalytics.png";
import style from "assets/jss/material-kit-pro-react/views/componentsSections/contentAreas.js";

const useStyles = makeStyles(style);

export default function SectionDataAnalysisTable() {
  const [checked, setChecked] = React.useState([1, 3, 5]);
  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  const classes = useStyles();
  const fillButtons = [
    { color: "info", icon: Person },
    { color: "success", icon: Edit },
    { color: "danger", icon: Close },
  ].map((prop, key) => {
    return (
      <Button justIcon size="sm" color={prop.color} key={key}>
        <prop.icon />
      </Button>
    );
  });
  const simpleButtons = [
    { color: "info", icon: Person },
    { color: "success", icon: Edit },
    { color: "danger", icon: Close },
  ].map((prop, key) => {
    return (
      <Button simple justIcon size="sm" color={prop.color} key={key}>
        <prop.icon />
      </Button>
    );
  });
  const roundButtons = [
    { color: "info", icon: Person },
    { color: "success", icon: Edit },
    { color: "danger", icon: Close },
  ].map((prop, key) => {
    return (
      <Button round justIcon size="sm" color={prop.color} key={key}>
        <prop.icon />
      </Button>
    );
  });
  return (
    <div className="cd-section" id="contentAreas">
      <h3 className={classes.title}>
        Big Data Analysis and Theory Development: A paradigm shift in business
        research methods
      </h3>
      <div id="tables">
        <GridContainer>
          <GridItem
            xs={12}
            sm={10}
            md={8}
            className={classes.mrAuto + " " + classes.mlAuto}
          >
            <Table
              striped
              tableHead={["Research Activities", "Small Data", "Big data"]}
              tableData={[
                [
                  "Philosophical paradigm",
                  <ul>
                    <li>Positivism</li>
                    <li>interpretivism</li>
                    <li>Pragmatic</li>
                    <li>Realism</li>
                  </ul>,
                  <ul>
                    <li>Realism (Critical realism)</li>
                  </ul>,
                ],
                [
                  "Role of theory",
                  <ul>
                    <li>Theoretical sensitivity</li>
                  </ul>,
                  <ul>
                    <li>Theoretical sensitivity (contested in literature)</li>
                  </ul>,
                ],
                [
                  "Research design",
                  <ul>
                    <li>Intrusive</li>
                    <li>Cross sectional; time series</li>
                    <li>
                      Grounded theory design allows for pre-theoretical analysis
                    </li>
                  </ul>,
                  <ul>
                    <li>Not intrusive</li>
                    <li>
                      Design allows for pre-theoretical analysis for contextual
                      information
                    </li>
                    <li>Iterative data collection in chosen time intervals</li>
                    <li>
                      Digital traces of behaviour without directly asking
                      participants questions
                    </li>
                    <li>Allows for simulation in real world setting</li>
                  </ul>,
                ],
                [
                  "Data type",
                  <ul>
                    <li>Structured, unstructured Multiple data sources</li>
                  </ul>,
                  <ul>
                    <li>
                      Structured, unstructured, granular, richer pictures; Six
                      V’s
                    </li>
                  </ul>,
                ],
                [
                  "Sampling issues",
                  <ul>
                    <li>n = a fixed number</li>
                    <li>Probability and non-probability sampling theories</li>
                    <li>
                      Single/multiple unit of analysis with a sequence of
                      observation at a single point in time or over time
                    </li>
                  </ul>,
                  <ul>
                    <li>
                      n= all observations in a selected and defined data source{" "}
                    </li>
                    <li>
                      Probability and non-probability Kim, J. K., and Wang,
                      Z. ( 2019) Sampling Techniques for Big Data Analysis
                    </li>
                    <li>Multiple units of analysis</li>
                  </ul>,
                ],
                [
                  "Data acquisition; and data quality",
                  <ul>
                    <li>Recruitment and selection of participants</li>
                    <li>Trustworthiness, credibility, integrity</li>
                  </ul>,
                  <ul>
                    <li>Less costly, but time consuming to clean data</li>
                    <li>Time to anonymise or aggregate the data</li>
                    <li>
                      Data empathy (how data was generated, purpose, generation
                      process
                    </li>
                    <li>Trustworthiness, credibility, integrity</li>
                  </ul>,
                ],
              ]}
              customCellClasses={[
                classes.textCenter,
                classes.padding0,
                classes.textRight,
                classes.textRight,
              ]}
              customClassesForCells={[0, 1, 5, 6]}
              customHeadCellClasses={[
                classes.textCenter,
                classes.textRight,
                classes.textRight,
              ]}
              customHeadClassesForCells={[0, 5, 6]}
            />
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem
            xs={12}
            sm={8}
            md={8}
            className={classes.mlAuto + " " + classes.mrAuto}
          >
            <Card plain blog className={classes.card4}>
              <CardHeader image plain>
                <a href="#pablito" onClick={(e) => e.preventDefault()}>
                  <img src={analytics} alt="..." />
                </a>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
