import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import servicesStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/servicesStyle.js";

const useStyles = makeStyles(servicesStyle);

export default function SectionServices() {
  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mlAuto, classes.mrAuto)}
        >
          <h2 className={classes.title}>
            We will help you start your writing journey
          </h2>
          <h5 className={classes.description}>
            You have come to the right place for ideas, insights and tips to
            create and write up your research projects. Creating and writing up
            a research project is almost like building a car. The process
            requires critical thinking skills and creativity. If I may use the
            engine as a metaphor for your research,academic writing is the
            aesthetics and the performance of your car - it reflects your
            critical thinking capabilities and the rigour of your research
            process.
          </h5>
          <h5 className={classes.description}>
            We are an institution of life long learning aiming to cultivate your
            creativity and inspire generation of academic authors that take
            pride and joy of their academic publications that are the essence of
            shaping progressive academic research. The Academic writing
            resources are curated primarily by Professor Pumela Msweli and will
            also feature fellow high esteemed authors in the academic writing
            fraternity. Prof. Msweli as protagonist of the platform she will add
            a flair of her personal touch by sharing blogs, podcasts of personal
            expereinces that help her countinously improve in her academic
            writing.
          </h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}
