import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Quote from "components/Typography/Quote.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import sectionTextStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionTextStyle.js";
import SectionBigDataDefinition from "./SectionBigDataDefinition.js";
import SectionWhyBigData from "./SectionWhyBigData.js";

import BigDataStats2019 from "assets/img/scientificDiagrams/2019BigDataStats.png";
import SectionSourceOfData from "./SectionSourceOfData.js";
import SectionDataAnalysisTable from "./SectionDataAnalysisTable.js";

const useStyles = makeStyles(sectionTextStyle);

export default function SectionText() {
  const classes = useStyles();
  const imgClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );
  return (
    <div className={classes.section} justify="center">
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.title}>What is Big Data?</h3>
          <Quote
            textClassName={classes.quoteText}
            text="“Large complex, data set characterized by volume and velocity”"
            author="Diebold, 2000"
          />

          <Quote
            textClassName={classes.quoteText}
            text="“Large, unstructured data from multiple sources with four attributes: volume, velocity, variety and exhaustibility ”"
            author="Kitchin and McArdle, 2016"
          />
        </GridItem>
      </GridContainer>
      <SectionBigDataDefinition />
      <SectionSourceOfData />
      <h3 className={classes.title}>Why we cannot ignore big data?</h3>
      <GridContainer justify="center">
        <GridItem xs={12} sm={4} md={4}>
          <br></br>
          <br></br>
          <br></br>
          <img src={BigDataStats2019} alt="..." className={imgClasses} />
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <p>
            According to the WEF (2019) this is how much data is generated
            daily:
          </p>
          <ul className={classes.listUnstyled}>
            <li>500 million Tweets are sent</li>
            <li>294 billion emails are sent</li>
            <li>4 Peta bytes are created on Facebook</li>
            <li>65 billion messages are sent on Whatsapp</li>
            <li>It is estimated that 463 exabytes will be created by 2025</li>
          </ul>
        </GridItem>
      </GridContainer>
      <SectionWhyBigData />
      <SectionDataAnalysisTable />
    </div>
  );
}
