import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft";
// core components
// react components for routing our app without refresh

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import Accordion from "components/Accordion/Accordion.js";
import cardProject6 from "assets/img/examples/card-project6.jpg";
import writeringImg from "assets/img/examples/Thinking1.jpg";
import { ARTICLE_DATA } from "./ArticleData";
// core components

import blogsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.js";
const useStyles = makeStyles(blogsStyle);
// @material-ui/icons

export default function SectionAbstracts() {
  const classes = useStyles();
  console.log(ARTICLE_DATA);
  return (
    <div className={classes.section}>
      <div className={classes.blog}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={10}
              md={10}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <br />
              <Card plain blog className={classes.card}>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <CardHeader image plain>
                      <a href="#pablito" onClick={(e) => e.preventDefault()}>
                        <img src={writeringImg} alt="..." />
                      </a>
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${writeringImg})`,
                          opacity: "1",
                        }}
                      />
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${writeringImg})`,
                          opacity: "1",
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8}>
                    <h6 className={classes.cardCategory}>
                      enterprise capability theoretical perspective
                    </h6>
                    <h3 className={classes.cardTitle}>
                      Reimagining student centeredness in higher education:
                      enterprise capability theoretical perspective
                    </h3>
                    <p className={classes.description}></p>
                    <Accordion
                      collapses={[
                        {
                          title: "Read Abstract",
                          content:
                            "The higher education environment has been disrupted not only by Covid 19, but also by the intensification of globalisation and advancement in technology. Such a disruption has created a dynamic environment that requires a new lens to understand the critical factors required not just for survival, but for high performing higher education institutions. In recent works scholars have started to conceptualise customer centredness as a dynamic enterprise capability which is closely related to organisational performance. Merging two theoretical strands, customer centredness and enterprise capabilities, this paper introduces a framework for harvesting value throughout the student walk from application to graduation by integrating student centeredness and higher education performance with higher education enterprise capability indicators including, collaborative partnerships, as well as timely and agile development and deployment of entrepreneurial and human capital skills. This paper seeks to expand on the extant view of customer centredness taking into consideration that higher education institutions operate in a dynamic environment that is continuously being disrupted by global forces and technological innovations. The key question around which this study is focused is: what are the enterprise capabilities required from the application stage to graduation in the student walk, that enhance university performance? Given the changed higher education environment post the Covid pandemic, and given the new ways of being, living and working, the higher education sector can no longer rely on the limitedness of primary data collected using surveys and interviews. The study presented in this paper thus addresses this gap by using Power BI data which is characterised by its volume, velocity, variety, and variability to map out a process for building and strengthening a student-centred dynamic capability in a higher education setting.",
                        },
                      ]}
                    />
                    <p className={classes.author}>
                      Authors <b>Pumela Msweli</b>, 10 March 2024
                    </p>
                    <Button round href="#pablo" color="danger">
                      <FormatAlignLeft className={classes.icons} />
                      <a
                        href="/asserts/Articles/Reimaginingstudentcenteredness.pdf"
                        target="_blank"
                      >
                        Read article
                      </a>
                    </Button>
                    <Button round href="#pablo" color="success">
                      <FormatAlignLeft className={classes.icons} />
                      <a
                        href="/asserts/Articles/Reimaginingstudentcenteredness.pptx"
                        target="_blank"
                        download="ReimaginingStudentCenteredness.pptx"
                      >
                        Read powerpoint presentation
                      </a>
                    </Button>
                  </GridItem>
                </GridContainer>
              </Card>
              {ARTICLE_DATA.map(
                ({
                  title,
                  abstract,
                  authors,
                  publishDate,
                  articlePath,
                  photo,
                  journal,
                }) => (
                  <Card plain blog className={classes.card}>
                    <GridContainer>
                      <GridItem xs={12} sm={4} md={4}>
                        <CardHeader image plain>
                          <a
                            href="#pablito"
                            onClick={(e) => e.preventDefault()}
                          >
                            <img src={photo} alt="..." />
                          </a>
                          <div
                            className={classes.coloredShadow}
                            style={{
                              backgroundImage: `url(${photo})`,
                              opacity: "1",
                            }}
                          />
                          <div
                            className={classes.coloredShadow}
                            style={{
                              backgroundImage: `url(${photo})`,
                              opacity: "1",
                            }}
                          />
                        </CardHeader>
                      </GridItem>
                      <GridItem xs={12} sm={8} md={8}>
                        <h6 className={classes.cardCategory}>{journal}</h6>
                        <h3 className={classes.cardTitle}>{title}</h3>
                        <p className={classes.description}></p>
                        <Accordion
                          collapses={[
                            {
                              title: "Read Abstract",
                              content: abstract,
                            },
                          ]}
                        />
                        <p className={classes.author}>
                          Authors <b>{authors}</b>, {publishDate}
                        </p>
                        <Button round href="#pablo" color="danger">
                          <FormatAlignLeft className={classes.icons} />
                          <a href={articlePath} target="_blank">
                            Read article
                          </a>
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </Card>
                )
              )}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Card
                raised
                background
                style={{
                  backgroundImage: "url(" + cardProject6 + ")",
                }}
              >
                <CardBody background>
                  <h6 className={classes.category}>Academic writing</h6>
                  <a
                    href="https://scholar.google.com/citations?user=YNeIukoAAAAJ&hl=en"
                    target="_blank"
                  >
                    <h3 className={classes.cardTitle}>Prof. Pumela Msweli</h3>
                  </a>
                  <p className={classes.category}>
                    Visit Google Scholar for more published academic writing
                    research
                  </p>
                  <Button round href="#pablo" color="danger">
                    <a
                      href="https://scholar.google.com/citations?user=YNeIukoAAAAJ&hl=en"
                      target="_blank"
                    >
                      Visit Google Scholar
                    </a>
                  </Button>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
