import React from "react";
// nodejs library that concatenates classes
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import professorProfile from "assets/img/faces/research-profile.jpg";
//const useStyles = makeStyles(sectionInterestedStyle);
import blogsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.js";

const useStyles = makeStyles(blogsStyle);

export default function SectionDescription() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer>
        <GridItem
          xs={12}
          sm={10}
          md={10}
          className={classes.mlAuto + " " + classes.mrAuto}
        >
          <Card plain blog className={classes.card}>
            <GridContainer>
              <GridItem xs={12} sm={5} md={5}>
                <CardHeader image plain>
                  <a href="#pablito" onClick={(e) => e.preventDefault()}>
                    <img src={professorProfile} alt="..." />
                  </a>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage: `url(${professorProfile})`,
                      opacity: "1",
                    }}
                  />
                </CardHeader>
                <h3 className={classes.title}>Pumela Msweli</h3>
                <h6>PhD, MBA, MPhil, BSc, Certified Integral Coach </h6>
                <h6>
                  Executive Dean and CEO Graduate School of Business Leadership
                </h6>

                <a href="https://orcid.org/0000-0001-5538-2681" target="_blank">
                  ORCID:  0000-0001-5538-2681
                </a>
                <br></br>
                <br></br>
              </GridItem>
              <GridItem xs={12} sm={7} md={7}>
                <p className={classes.description}>
                  Professor Msweli obtained her Master in Business
                  Administration at the University of Exeter, cum laude. She has
                  PhD qualification from Exeter University in the United
                  Kingdom, and an MPhil in Development Finance from Stellenbosch
                  University. Prof Msweli is a Certified Integral Coach, a
                  qualification she obtained from the University of Cape Town.
                  Msweli has taught at the University of Witwatersrand and at
                  University of Natal which became the University of
                  KwaZulu-Natal after the merger. She has also served as a
                  Professor of Corporate Governance at the University of South
                  Africa.
                </p>
                <p className={classes.description}>
                  Additionally, Msweli has served as a visiting scholar at St
                  Mary’s University in Canada, University of Mauritius,
                  Friedrich-Alexander University, Westminster University and
                  most recently Romanian American University. An accomplished
                  researcher, Professor Msweli has written extensively and has
                  published her work in local and international journals. She
                  has supervised 20 doctoral students to completion, two of
                  which are now Professors.
                </p>
                <p className={classes.description}>
                  Msweli has also supervised over 40 masters students to
                  completion. Msweli participated in numerous international
                  collaborative research projects located in South Africa,
                  Uganda, Mauritius, Zimbabwe, Tanzania, Egypt, Ethiopia,
                  Botswana, Canada, USA, UK, Germany, France, Finland and
                  Sweden. She has extensive experience in leadership.
                </p>
                <p>
                  <b>
                    Professor Msweli is CEO and Executive Dean in the Graduate
                    School of Business Leadership at the University of South
                    Africa.
                  </b>
                </p>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
