export const ARTICLE_DATA = [
  {
    title:
      "Multiple logics, multiple universes: epistemic significance of the lost African wisdom",
    abstract:
      "Many-worlds quantum theory states that there are multiple logics and multiple universes that coexist simultaneously; and there is a mechanism for interacting between these universes that somehow permits all energy states to be accessible in some way. I take an ontological stance that there are multiple logics that require specific mechanisms to access different forms of energy, different communication modalities and different ways to derive meaning from the physical world. I present a different spin in explaining lost African value systems and the implications of such loss to poverty of the soul – a possible precursor to indigence. I explore the intersectionality between quantum theory of unconsciousness, with integral theory. I endeavour to make sense of the African myths and legends such as– ‘ndakuthumela ngezulu’– (meaning I will summon the cosmic energy of rain, storm and lightening and redirect it to you). This myth is known in South African local villages and homesteads as a signal for human- invoked cosmic transformation that may be effected at will. I explore the link between the mechanism used by indigenous knowledge holders to transform nature from one form to the other, as is the case in rain making, with the quantum physics many-worlds theory. Against the backdrop of quantum theory of unconsciousness I explore the African myths and values of “Maat” to make sense of how the the union and balance of the multiple energy centers (chakras) play a role in accessing multiple universes. Building on these conceptions, I show how music and dance embedded in indigenous African rituals portray the union of the multiple intelligences and multiple energy centers that underscore the epistemic significance of the lost African wisdom.",
    authors: "Pumela Msweli",
    publishDate: "May",
    articlePath: "",
    photo: "/asserts/images/Thinking1.jpg",
    journal: "",
  },
  {
    title:
      "Factors contributing to bank selection choices: a generation theory perspective",
    abstract:
      "In South Africa retail banking has become a highly competitive industry with 14 locally controlled banks, six foreign controlled banks, and 13 branches of foreign banks and numerous representative offices of foreign and mutual banks. To attract the target market and operate in this competitive environment, banks would profit from a sharp understanding of the bank selection criteria that clients consider valuable when selecting a bank. The fulfilment of customer wishes requires that companies understand which aspects of their product and services are most valued by the customer. Literature has already revealed that value will remain the benchmark against which financial service providers are measured. What constitutes value for customers is the question at stake in this work. It is equally important to understand how customers from different generation backgrounds select a particular bank in order to design an effective segmentation strategy. It is against this background that this work poses the following questions: What are the factors that influence bank selection? How are these factors different for customers from different generation backgrounds? This paper compares bank selection criteria of baby boomers, generation X and Generation Y bank clients across the globe.",
    authors: "Pumela Msweli, Pieter Naude",
    publishDate: "26 March 2015",
    articlePath: "/asserts/Articles/BBS_en_2015_01_Msweli.pdf",
    photo: "/asserts/images/Thinking1.jpg",
    journal: "Banks and Bank Systems",
  },
  {
    title:
      "On carbon emission reduction and firm performance: example from 3M Company",
    abstract:
      "Recent research indicates apparent business scepticism about the business benefit in carbon reduction. This paper examines the relationship between carbon emission reduction program of 3M Company and its performance (represented by dividend per share). Using the least square multiple regression statistics, findings from the test indicate a significant relationship between carbon emission reduction of 3M Company and its dividend per share within the years studied, indicating that, against apparent business concern, 3M’s carbon reduction contributed significantly to improve its dividend per share (within the years studied). The paper thus concludes it is likely that some companies may experience enhanced dividend per share if engaged in a carbon reduction program. The paper suggests that further studies on the effect of emission reduction on firm performance should use single cases as this may possess greater propensity to show clearer result.",
    authors: "Collins C. Ngwakwe, Pumela Msweli",
    publishDate: "02 July 2013",
    articlePath: "/asserts/Articles/ee_2013_02_Ngwakwe.pdf",
    photo: "/asserts/images/Thinking1.jpg",
    journal: "Environmental Economics",
  },
  {
    title: "An analysis of corporate community engagement in Zimbabwe",
    abstract:
      "A notable conflict in extractive industries is a clash of competing interests. A desire to address this conflict has resulted in the development of corporate social responsibility (CSR) theories. The aim of this paper is to provide an indepth understanding of factors that have a significant impact on positive corporate community engagement, by focusing on the engagement aspect of corporate social responsibility. The study is centered around the following three questions: (1) How should corporates engage with local communities to ensure positive engagement outcomes? (2) What is the role of communities in the corporate engagement process? (3) What are the key components of Corporate Community Engagement? Using qualitative and quantitative data instruments, empirical evidence was generated from five communities in close proximity to platinum and diamond mining companies in Zimbabwe. The qualitative data gathering process generated ten key components of corporate community engagement. Exploratory factor analysis was used to assess the factorial validity of these components. The factor solution resulted in three significant components: (1) value adding partnerships; (2) trust; and (3) effective communication. The paper concludes by giving consideration to corporate best practices that lead to enriching understanding of local communities and effective engagement.",
    authors: "Pumela Msweli, Tawaziwa Wushe",
    publishDate: "27 November 2014",
    articlePath: "/asserts/Articles/ee_2014_04_Msweli.pdf",
    photo: "/asserts/images/Thinking1.jpg",
    journal: "Environmental Economics",
  },
  {
    title:
      "Human capital development: what can South Africa learn from Botswana?",
    abstract:
      "South Africa and Botswana share a number of commonalities, although the two countries are distinctly different in terms of economic performance, population size, and human capital development. This paper provides analysis of human capital development factors that contribute to differences in the human capital index score of the two countries. In 2013, the World Economic Forum published the first edition of the Human Capital Report which measures human capital using an index based on four pillars: (1) education; (2) health and wellness; (3) workforce employment; and (4) enabling environment. Botswana’s human capital index score is higher than that of South Africa even though both countries have negative human capital index scores. The indicators that show the fundamental differences between South Africa and Botswana are education, health and wellness as well as workforce employment. The differences are discussed in terms of their implications to policy formulation.",
    authors: "Pumela Msweli",
    publishDate: "05 June 2015",
    articlePath: "/asserts/Articles/ee_2015_01spec.issue_Msweli.pdf",
    photo: "/asserts/images/Thinking1.jpg",
    journal: "Environmental Economics",
  },
  {
    title:
      "Theorizing and institutionalizing operation Sukuma Sakhe: a case study of integrated service delivery",
    abstract:
      "The authority of South Africa is in the midst of a profound economic crisis precipitated by high levels of unemployment and inequality, marked by political shifts unprecedented in scope subsequent to the 2016 local government elections. The recent election outcomes reveal disturbances in the social, political and economic systems of the country. These disturbances have been precipitated by low economic growth trajectory and poor investment in service delivery infrastructure. The central thesis of this paper is that in addressing the triple challenge of poverty, inequality and unemployment, a symptomatic approach of addressing social ills and service delivery will not work. This paper draws from institutional theory as a sense-making mechanism to produce a scientific approach to integrated service delivery. Using the tenets of the critical reflecting methodological approach, institutional theory is deconstructed to provide an understanding of how to operationalize integrated service delivery across functions in a scientific fashion.",
    authors: "Nonhlanhla Ignatia Ndlovu, Pumela Msweli",
    publishDate: "21 December 2016",
    articlePath: "/asserts/Articles/ee_2016_04cont_Ndlovu.pdf",
    photo: "/asserts/images/Thinking1.jpg",
    journal: "Environmental Economics",
  },
  {
    title: "Commonwealth Games: can Africa afford the games?",
    abstract:
      "The Commonwealth Games Federation recently awarded the hosting of the 2022 Games to Durban, South Africa. This will be the first time the Games are hosted in the African continent. The paper looks into whether tangible benefits for hosting mega-events are less or more important than intangible benefits. In investigating this issue, the paper takes a political geography theoretical stance for its ability to provide normative ideological content for explaining intangible and tangible values for hosting Commonwealth Games. The paper looks at pros and cons for investing in mega-event infrastructure and also looks at the cost of hosting the Games for cities. The 2010 Commonwealth Games in Delhi budgeted $240 million for the entire Games, but the actual costs after the Games were found to be $1.7 billion. Similarly, in 2014 Glasgow, the cost of the Games was calculated at £575 million ($690 million). The paper looks at whether South Africa as the 2022 Commonwealth Games host can afford the games. It also considers the impact of the Games on national identity, national pride and patriotism here identified as novelty values that preserve social cohesion, a social attribute that has to be in place to build a robust economy. The paper concludes by noting that the success of the Games will depend on deliberate investment in catalytic facilities in areas that will be sustained post event. The amount of novelty value generated out of the Commonwealth Games will depend on how successful the Games are and on how the communities are able to interact with the facilities post event.",
    authors: "Musa Gumede, Vusi Mazibuko, Pumela Msweli",
    publishDate: "05 April 2017",
    articlePath: "/asserts/Articles/PMF_2017_01_Gumede.pdf",
    photo: "/asserts/images/Thinking1.jpg",
    journal: "Public and Municipal Finance",
  },
  {
    title:
      "Managing core university business performance in the wake of ‘fees must fall’ context: a legitimacy theoretical perspective",
    abstract:
      "This paper uses the legitimacy theory as a lens to identify the most sustainable performance model to defend and maintain the legitimacy of higher education institutions in the midst of ‘fees must fall’ crisis. The body of evidence presented demonstrates that universities have at least seven critical stakeholders that control resources needed for survival, growth and legitimacy. Using this evidence, this study builds a model that looks at the impact of fee income and workload on success. The model was tested using annual time series panel data for the period 2009-2013 across 23 public universities in South Africa. The model examined the effect of fee income and workload on success rate. Basing the arguments on the notion that students are a critical constituency, with a strong influence on the amount of fee income an institution may amass, the model tested the relationship between performance and fee income using success rate as a proxy for performance. The findings showed that not only is fee income a stronger predictor of success rate, but also that fee income is negatively related to success rate of students. Policy implications of the findings are discussed.",
    authors: "Pumela Msweli, Timothy Hutton",
    publishDate: "26 December 2016",
    articlePath: "/asserts/Articles/PMF_2016_04_Msweli.pdf",
    photo: "/asserts/images/Thinking1.jpg",
    journal: "Public and Municipal Finance",
  },
  {
    title:
      "An analysis of board attributes that contribute to decision quality in state-owned companies in South Africa",
    abstract:
      "Using Ordinary Least-Squares (OLS), the paper empirically examines the effect of board attributes that literature has shown to have an effect on board decision quality. The following five variables are incorporated in a model that seeks to identify the strongest predictor of board decision quality: (1) board independence, (2) effort norms, (3) expert knowledge and skill, (4) cognitive conflict and (5) information quality. The findings show that information quality is the strongest predictor of board decision quality followed by expert knowledge and skill. As expected, expert knowledge does not only increase the cognitive capacity of the board, but it also positively affects company competitiveness. The findings also show that cognitive conflict has a negative association with decision quality. The paper argues that political influence exerted by board political appointees may explain the negative relationship between cognitive conflict and board decision quality. The major contribution of this paper is that it provides a 28-item instrument that can be used practically by public entity boards in the reflective process to improve board decision quality. The paper concludes by offering avenues for further research.",
    authors: "Pumela Msweli, Shamila Singh",
    publishDate: "16 May 2014",
    articlePath: "/asserts/Articles/PMF_2016_04_Msweli.pdf",
    photo: "/asserts/images/Thinking1.jpg",
    journal: "Problems and Perspectives in Management",
  },
];
