import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Code from "@material-ui/icons/AccountBalance";
import FormatPaint from "@material-ui/icons/Tablet";
import Dashboard from "@material-ui/icons/Twitter";
import SensorOccupiedIcon from "@material-ui/icons/Alarm";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import ViewCarousel from "@material-ui/icons/CreditCard";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js";

import bg9 from "assets/img/bg9.jpg";
import blockChain from "assets/img/scientificDiagrams/blockChain.png";

const useStyles = makeStyles(featuresStyle);

export default function SectionSourceOfData({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      {/* Feature 5 START */}
      <div
        className={classes.features5}
        style={{ backgroundImage: `url(${bg9})` }}
      >
        <GridContainer>
          <GridItem
            xs={12}
            sm={8}
            md={8}
            className={
              classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
            }
          >
            <h2 className={classes.title}>Sources of Big Data</h2>
          </GridItem>
          <div className={classes.container}>
            <GridContainer className={classes.gridContainer}>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <InfoArea
                  vertical
                  className={classes.infoArea5}
                  icon={Code}
                  title="Administration"
                  description={
                    <p>
                      {" "}
                      Student System, EDS, Financial performance reports,
                      Marketing reports, Programme administration files, IPMS
                      system, Quality Assurance Reports, Teaching and Learning
                      reports, HR: EE Reports, ORACLE Recruitment, Selection,
                      and Retention{" "}
                    </p>
                  }
                  iconColor="info"
                />
              </GridItem>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <InfoArea
                  vertical
                  className={classes.infoArea5}
                  icon={FormatPaint}
                  title="Mobile Communication"
                  description={
                    <>
                      <p>Mobile phone data and App data</p>
                    </>
                  }
                  iconColor="danger"
                />
              </GridItem>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <InfoArea
                  vertical
                  className={classes.infoArea5}
                  icon={Dashboard}
                  title="Social Media / Websites"
                  description={
                    <p>
                      Facebook, WhatsApp, Twitter, Instagram, YouTube and Web
                      searches (Google) clickstream
                    </p>
                  }
                  iconColor="primary"
                />
              </GridItem>
            </GridContainer>
            <GridContainer className={classes.gridContainer}>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <InfoArea
                  vertical
                  className={classes.infoArea5}
                  icon={ViewCarousel}
                  title="Transactions of process generated data"
                  description={
                    <p>
                      Product and service fee, scanners, Biometry data, Credit
                      card data & Stock market trades
                    </p>
                  }
                />
              </GridItem>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <InfoArea
                  vertical
                  className={classes.infoArea5}
                  icon={SensorOccupiedIcon}
                  title="Sensors"
                  description={
                    <p>
                      Automated no. plate readers, Satellite images, Pollution
                      and sound sensors
                    </p>
                  }
                />
              </GridItem>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <InfoArea
                  vertical
                  className={classes.infoArea5}
                  icon={CameraAltIcon}
                  title="Cameras/Lasers"
                  description={<p>Digital CCTV and Lidar mapping</p>}
                />
              </GridItem>
            </GridContainer>
          </div>
        </GridContainer>
        <GridContainer>
          <GridItem
            xs={12}
            sm={8}
            md={8}
            className={classes.mlAuto + " " + classes.mrAuto}
          >
            <Card plain blog className={classes.card4}>
              <CardHeader image plain>
                <a href="#pablito" onClick={(e) => e.preventDefault()}>
                  <img src={blockChain} alt="..." />
                </a>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
      {/* Feature 5 END */}
    </div>
  );
}
