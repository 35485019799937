/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import SchoolIcon from "@material-ui/icons/School";
import People from "@material-ui/icons/People";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Badge from "components/Badge/Badge.js";
import Muted from "components/Typography/Muted.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import SectionResume from "AcademicWritingWebsite/views/ProfilePage/AboutUsPage/Sections/SectionResume.js";
import podcastImg from "assets/img/academicWritingImages/LibraryImages/podcast.jpg";
import youtubeImg from "assets/img/academicWritingImages/LibraryImages/youtube.png";
import kendall from "assets/img/academicWritingImages/LibraryImages/library4.jpg";
import cardProfile2Square from "assets/img/academicWritingImages/LibraryImages/creativity.jpg";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

const useStyles = makeStyles(profilePageStyle);

export default function ProfilePage({ ...rest }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      <div className={classNames(classes.main)}>
        <div className={classes.container}>
          <div className={classes.profileTabs}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={7} className={classes.gridItem}>
                <SectionResume />
              </GridItem>
              <GridItem xs={12} sm={12} md={2} className={classes.gridItem}>
                <h4 className={classes.title}>Stats</h4>
                <ul className={classes.listUnstyled}>
                  <li>
                    <b>19</b> - Supervised Doctoral Students
                  </li>
                  <li>
                    <b>41</b> - Supervised Masters Students
                  </li>
                  <li>
                    <b>80</b> - Scholarly Publications
                  </li>
                  <li>
                    <b>20+</b> - Commisioned Business Research Reports
                  </li>
                </ul>

                <hr />
              </GridItem>
            </GridContainer>
          </div>
          <Clearfix />
        </div>
      </div>
    </div>
  );
}
