import React from "react";
// nodejs library that concatenates classes
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import ProfilePage from "./ProfilePage.js";
import Badge from "components/Badge/Badge.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import professorProfile from "assets/img/faces/professorProfile.jpg";
import Info from "components/Typography/Info.js";
import FormatAlignLeft from "@material-ui/icons/CloudDownload.js";
//const useStyles = makeStyles(sectionInterestedStyle);
import blogsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.js";

const useStyles = makeStyles(blogsStyle);

export default function SectionDescription() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer>
        <GridItem
          xs={12}
          sm={10}
          md={10}
          className={classes.mlAuto + " " + classes.mrAuto}
        >
          <Card plain blog className={classes.card}>
            <GridContainer>
              <GridItem xs={12} sm={5} md={5}>
                <CardHeader image plain>
                  <a href="#pablito" onClick={(e) => e.preventDefault()}>
                    <img src={professorProfile} alt="..." />
                  </a>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage: `url(${professorProfile})`,
                      opacity: "1",
                    }}
                  />
                </CardHeader>
              </GridItem>
              <GridItem xs={12} sm={7} md={7}>
                <Info>
                  <h6 className={classes.cardCategory}>
                    ACADEMIC WRITING COACH
                  </h6>
                </Info>
                <p className={classes.description}>
                  My name is Pumela Msweli, I am your writing coach. I currently
                  serve as the Executive Dean and CEO of the School of Business
                  Leadership at UNISA. I have taught at the University of
                  Witwatersrand and at University of Natal which became the
                  University of KwaZulu-Natal after the merger. I served as a
                  visiting scholar at St Mary’s University in Canada, University
                  of Mauritius and Westminster University in the UK.
                </p>

                <Badge color="rose">Academic writting</Badge>
                <Badge color="rose">Research</Badge>
                <Badge color="rose">Critical thinking</Badge>
                <Badge color="rose">THINK AND WRITE AUTHENTICALLY </Badge>
                <Badge color="rose">Critical thinking</Badge>

                <Badge color="rose">Think and Write Authentically</Badge>
                <Badge color="rose">Academic Rhetoricg</Badge>
                <Badge color="rose">Theorising and Conceptualising</Badge>
                <Badge color="rose">
                  Identifying a Relavant Theoretical Framework{" "}
                </Badge>
                <Badge color="rose">Critical Theory and Literatureg</Badge>
                <Badge color="rose">
                  Research Methods for Different Worldviews
                </Badge>
                <Badge color="rose">
                  Writing up Qualitative Research Chapter
                </Badge>
                <Badge color="rose">
                  Writing up Quantitative Research Charpter
                </Badge>
                <Badge color="rose">Contributing to knowledge</Badge>

                <br></br>
                <br></br>
                <Button round href="#pablo" color="info">
                  <FormatAlignLeft className={classes.icons} />
                  <a
                    href="/asserts/Articles/Revised CV Prof Msweli 10. March 2024.pdf"
                    target="_blank"
                  >
                    Download Resume
                  </a>
                </Button>
              </GridItem>
            </GridContainer>
            <ProfilePage />
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
