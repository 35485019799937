import React from "react";
import { Route, Routes } from "react-router-dom";

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";

// pages for this product
import AboutUsPage from "AcademicWritingWebsite/views/AboutUsPage/AboutUsPage.js";
import ThinkingAndWritingLogicalPage from "AcademicWritingWebsite/views/BlogPostThinkingAndWritingLogicalPage/BlogPostThinkingAndWritingLogicalPage.js";
import BlogPostCultivateCreativityPage from "AcademicWritingWebsite/views/BlogPostCultivateCreativityPage/BlogPostCultivateCreativityPage.js";
import BlogPostsPage from "AcademicWritingWebsite/views/BlogPostsPage/BlogPostsPage.js";
import BeLightPoemPage from "AcademicWritingWebsite/views/PoetryPage/BeLightPoemPage.js";
import YouAreWomenPage from "AcademicWritingWebsite/views/PoetryPage/YouAreWomenPage.js";
import WithinYouPoemPage from "AcademicWritingWebsite/views/PoetryPage/WithinYouPoemPage.js";
import BlogIsomorphismPage from "AcademicWritingWebsite/views/BlogIsomorphismPage/BlogIsomorphismPage.js";
import LectureVideosPage from "AcademicWritingWebsite/views/LectureVideosPage/LectureVideosPage.js";
import BlogPostBigDataPage from "AcademicWritingWebsite/views/BlogBigDataPage/BlogPostBigDataPage.js";
import ArticlesPage from "AcademicWritingWebsite/views/ArticlesPage/ArticlesPage.js";
import ComponentsPage from "views/ComponentsPage/ComponentsPage.js";
import ContactUsPage from "views/ContactUsPage/ContactUsPage.js";
import PoetryPage from "AcademicWritingWebsite/views/PoetryPage/PoetryPage.js";
import RoyalPoemPage from "AcademicWritingWebsite/views/PoetryPage/RoyalPoemPage.js";
import PresentationPage from "views/PresentationPage/PresentationPage.js";
import ProfilePage from "AcademicWritingWebsite/views/ProfilePage/ProfilePage.js";
import ResearchProfilePage from "AcademicWritingWebsite/views/ResearchProfilePage/ResearchProfilePage.js";

export default function App() {
  return (
    <>
      <Routes>
        <Route exact index element={<AboutUsPage />} />
        <Route exact path="/about-us" element={<PresentationPage />} />
        <Route
          exact
          path="/ThinkingAndWritingLogicalPage"
          element={<ThinkingAndWritingLogicalPage />}
        />
        <Route exact path="/blog-posts" element={<BlogPostsPage />} />
        <Route
          path="/BlogPostCultivateCreativityPage"
          element={<BlogPostCultivateCreativityPage />}
        />
        <Route path="/BlogIsomorphismPage" element={<BlogIsomorphismPage />} />
        <Route path="/LectureVideosPage" element={<LectureVideosPage />} />
        <Route path="/BlogPostBigDataPage" element={<BlogPostBigDataPage />} />
        <Route path="/PoetryPage" element={<PoetryPage />} />
        <Route path="/BeLightPoemPage" element={<BeLightPoemPage />} />
        <Route path="/RoyalPoemPage" element={<RoyalPoemPage />} />
        <Route path="/WithYouPoemPage" element={<WithinYouPoemPage />} />
        <Route path="/YouAreWomenPage" element={<YouAreWomenPage />} />
        <Route path="/ArticlesPage" element={<ArticlesPage />} />
        <Route path="/components" element={<ComponentsPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/profile-page" element={<ProfilePage />} />
        <Route path="/research-profile" element={<ResearchProfilePage />} />
      </Routes>
    </>
  );
}
