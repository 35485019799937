import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Success from "components/Typography/Success.js";

import blogsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.js";

import bigDataSixV from "assets/img/scientificDiagrams/bigDataSixV.png";
import bigDataStar from "assets/img/scientificDiagrams/bigDataStar.png";

const useStyles = makeStyles(blogsStyle);

export default function SectionBigDataDefinition({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      {/* Blogs 4 START */}
      <div className={classes.blog}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <Card plain blog className={classes.card4}>
                <CardHeader image plain>
                  <h3 className={classes.cardTitle}>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      BIG DATA SIX V’S
                    </a>
                  </h3>

                  <a href="#pablito" onClick={(e) => e.preventDefault()}>
                    <img src={bigDataSixV} alt="..." />
                  </a>
                </CardHeader>
                <CardBody plain>
                  <Success>
                    <h6 className={classes.cardCategory}>
                      GUNTHER ET AL (2017) ATTRIBUTES BIG DATA TO SIX V’S
                    </h6>
                  </Success>

                  <CardHeader image plain>
                    <br></br>
                    <br></br>
                    <br></br>

                    <a href="#pablito" onClick={(e) => e.preventDefault()}>
                      <img src={bigDataStar} alt="..." />
                    </a>

                    <Success>
                      <h6 className={classes.cardCategory}>
                        What is Big Data? Kitchin and McArdle (2016)
                      </h6>
                    </Success>
                  </CardHeader>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Blogs 4 END */}
    </div>
  );
}
