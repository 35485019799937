export const BooksAndChapters = [
  "P. Msweli (2011) How to write research proposal: A practical guide for business students. Cape Town: Juta Publishers",
  "P. Msweli-Mbanga (2003) Direct Selling in Africa: Eight Rs in Building a Successful Direct Selling Business. Cape Town: New Africa Books.",
  "M. Cant, C. Bothma, T. Drotsky, M. du Toit, F. Herbst, S. Linkenberg, R. Marchado, P. Msweli-Mbanga, M. Robberts, C. Stanford, J. Strydom, N. van Heerden (2004) Personal Selling, Johannesburg: Juta Publishers (Book Chapter)",
  "Burgess, S and Bothma, C (Editors et al) (2005) International Marketing, Cape Town: Oxford University Press, South Africa (ISBN: 019576126X) - (Book Chapter).",
];

export const PeerReviewJournals = [
  "Msweli, P and X E Kula (2023). Voices of Women in Boards Count, Effective Participation Counts More: A Board Gender Diversity Theoretical Framework; Ovidius University Annals. Economic Sciences Series - Vol. XXIII, Issue 1/2023.",

  "Msweli P and DL Ryneveldt (2023). A framework for implementing a Twin Peak Financial Regulatory Model: Institutional Logics Theoretical Perspective; Ovidius University Annals. Economic Sciences Series - Vol. XXIII, Issue 1/2023",

  "Stormer MD, and Msweli P (2023). Welfare effects of the AfCFTA on LDCs in Africa: A Computable General Equilibrium Analysis. Journal of Public Administration, September 2023, Vol 58, Issue No 3",

  "Gumede M, Mazibuko V and Msweli P (2017) Commonwealth Games Beyond Socioeconomic Sensibilities: Towards a model to quantify nonfinancial value, Journal of Public and Municipal Finance, Vol 6, Issue 1, p57-61.",

  "Msweli P and Hutton T (2016) Managing core university business performance in the wake of “fees must fall” context: a legitimacy theoretical perspective, Public and Finance Management, Vol 5, issue 4, pp 30-39. (IBSS listed journal).",

  "Ndlovu NI and Msweli P (2016) A theoretical framework for Poverty Reduction at Local Government Level Public and Finance Management, Vol 5, issue 4. (IBSS listed journal)",

  "Msweli P (2015). A framework to determine SME fitness for Venture Capital Funding: A sub Saharan context. Journal of Green Economy and Develop., Vol1, Issue 2; pp1-10.",

  "Msweli P (2015). Human Capital Development: What can South Africa learn from Botswana. Environmental Economics, Vol 6, Issue 1, pp 144-151. (IBSS Journal).",

  "Msweli P (2015). From 2015 MDGs to 2030 Sustainable Development Goals: Is South Africa ready for the challenge? An Editorial Note. Journal of Green Economy and Development, Vol1, Issue 1; pp1-8.",

  "Msweli P and Naude P (2015) Bank Selection Choices: A generation Theory Perspective. Banks and Bank Systems, Vol 10, Issue 1, pp97-102. (IBSS Journal).",

  "Msweli P and Wushe T (2014). An analysis of corporate community engagement in Zimbabwe. Environmental Economics, Vol 5, Issue 4: pp15-22. (IBSS Journal)",

  "Msweli P. and Singh S. (2014). An analysis of board attributes that contribute to decision quality in State Owned Companies in South Africa. Problems and Perspectives in Management, Vol 12, Issue 2: pp 85-92. (IBSS Journal).",

  "Ngwakwe CC and Msweli P (2013). On Carbon Emission Reduction and firm performance: Example from 3M Company.  Environmental Economics, Vol 4, Issue 2; pp 52-59. (IBSS Journal)",

  "Msweli P (2013). Conceptual Definition, Content Validity and Dimensionality of the Internationalisation Construct: A South African Higher Education Perspective. Africa Insight, Vol 43 (Issue 1): 45-40. (IBSS Journal).",

  "Msweli P, Wushe T and Mandudzo WC (2012). Corporate Community Engagement: Literature search for construct validation. A case of the extractive industry in Zimbabwe.",

  "Ngobese A.C.N. and Msweli P (2013). “Tracking Millennium Poverty Reduction in the Province of KwaZulu-Natal” Journal of Public Administration, Volume 48, Issue 2, pp209-224. (IBSS Journal)",

  "Msweli, P. (2012). “Mapping out the interplay between Open Distance Learning and Internationalisation Principles.” International Review of Research in Open and Distance Learning (IRRODL), Volume 13 (3): 144-163. (ISI Journal)",

  "Msweli, P (2012). “Retaining Generation X Academics in Higher Education Institutions” Annals of Management Science, Vol. 1, No. 1, December 2012, 83-96. (Cabell Journal)",

  "Mkhize, M and Msweli, P (2011) The Impact of female business leaders on the performance of listed companies in South Africa. South African Journal of Economics and Management Science, Volume 14, Issue 1: pp1-7, ISSN 2222-3426. (ISI Journal)",

  "Msweli-Mbanga P. & Mkhize H (2007) “The risk-adjusted performance of companies with women directors: A South African Case. South African Journal of Economic Management, Volume 10 (2) pages 207-213. (ISI Journal)",

  "H. Mkhize and P. Msweli-Mbanga (2006). “A critical review of the restructuring of the South African capital market.” International Review of Business Research Papers, Vol.2 (2): 80-91. (IBSS Journal)",

  "Msweli-Mbanga, P. & Potwana, N. (2006) “Modelling participation, resistance to change, and organisational citizenship behaviour: A South African case” South African Journal of Business Management. Volume 37 (1). (ISI Journal)",

  "Msweli-Mbanga, P., Fitzgerald P. & Mkhize A. (2005) “Representation of Women Directors in JSE Securities Exchange Listed Companies: Institutional Theory Perspective.” South African Journal of Labour Relations. Volume 29(2). (IBSS Journal)",

  "Msweli-Mbanga, P (2004) “Modelling Switching Behaviour of Direct Selling Customers” South African Journal of Economics and Management Science, Vol. 7 (2): 257-278.       (ISI Journal)",

  "P. Msweli-Mbanga (2004) “Predicting Turnover Behaviour of Direct Salespeople.” Southern African Business Review. Volume 8(3): 16-25. (IBSS Journal).",

  "Brodie S., Albaum G., D. R. Chen, Kennedy R. Msweli-Mbanga P. and Oksanen-Ylikoski E. (2004) “Public Perceptions of Direct Selling: An International Perspective” University of Westminster Press ISBN 0-9539845-5-9.",

  "Msweli-Mbanga & Chen-Tao Lin (2003) “Redefining Performance in the Direct Selling Industry” South African Journal of Business Management, Vol. 34, (3):",
  "Msweli P. and Sargeant A. (2001) “Modelling Distributor Retention in Network Marketing Organisations” Marketing Intelligence and Planning, Vol. 19, No. 7, pp. 507-514.",

  "Sargeant A. and Msweli P. (1999) “Network Marketing in South Africa: Just What is the Perception?” Journal of the International Consumer Marketing, Vol. 11, No. 3, pp. 51 – 66.",
];

export const ConferencePaper = [
  "P Msweli (2024). “Legitimacy and connectedness of South African Universities to their local economies: Lessons from Mondragon”; 23rd European Conference on Research Methods for Business and Management Studies, 4-5 July, 2024 (Paper accepted).",

  "P Msweli (2024) Reimagining student centeredness in higher education: Enterprise Capability Theoretical Perspective; 18th International Technology ducation and Development Conference, Valencia (Spain) 4-6 March; Electronic Conference Proceedings, DOI: 10.21125/inted.2024",

  "P Msweli and S Gama (2023). “The welfare effects of Africa Continental Free Trade Agreement (AfCFTA) on the manufacturing sector in South Africa; 30th RSEP International Conference on Economics, Finance & Business,  Review of Socio-Economic Perspectives (RSEP) & University of Warsaw, 27-28 April 2023 in Warsaw.",

  "R. Steyn and P Msweli (2023) Exploring the concept of Ubuntu and Ubuntu leadership; Leveraging Artificial Intelligence for ‘Creative’ and Possibly valuable support in Scoping Reviews, 16th International Business Conference, 24-27 September 2023, Swakopmund, Namibia.",

  "P  Msweli (2023) Breaking the Spell of Institutional Isomorphism: Conceptualizing Engaged Scholarship in a Business School. The European Conference on Education University College London & SOAS, University of London | Thursday, July 13, 2023 to Monday, July 17, 202 (Paper Accepted for publication).",
  "P Msweli (2023) A conceptual bridge where critical reflexivity meets the leadership warrior mystique archetype  19th European Conference on Management Leadership and Governance, 23 - 24 November 2023, Bayes Business School, City, University of London, UK.",

  "Blankson C., Nguyen T. Elliot E.A., Ahmend S.A. Msweli, P. (2022). Africa meets America: The Impact of Collectivism and Individualism on Attitude Towards Shopping. 22nd Academy Of African Business And Development Conference Proceedings (May 17th – May 21st, 2022), University of Professional Studies, Accra.",

  "Msweli, P and Tabane, M. (2022) Critical Reflective Assessment Technique: A Framework to Cultivate Engaged Scholarship In Business Education. EduLearn2022, 14th annual International Conference on Education and New Learning Technologies Palma de Mallorca (Spain). 4th - 6th of July, 2022.",

  "Ndlovu, S.K., Msweli P. Khoza F.T. (2021). A paradox of Access and Success in Higher Education in South Africa; 4th annual International Conference of Education, Research and Innovation; Online Conference. 8-9 November, 2021. ISBN: 978-84-09-34549-6 / ISSN: 2340-1095 doi: 10.21125/iceri.2021; Publisher: IATED.",

  "Rajah S and Msweli P (2016). Indigenous knowledge systems and civic mindedness in child development: towards a model of Ubuntu consciousness, Journal of Green Economy and Development Conference, 13-15 July, Salt Rock.",

  "Ngaka M and Msweli P (2016). A visual ethnographic study of indigenous social capital formation and social cohesion, Journal of Green Economy and Development Conference, 13-15 July, Salt Rock.",

  "Hutton T and Msweli P (2016) Towards a sustainable cost recovery model for structuring fees in the higher education sector in South Africa, Green Economy and Development Conference.",

  "Ngaka M and Msweli P (2015). Primordial Spirituality and Traditional Livelihood Practices: Ethics Structures",

  "Lombo S and Msweli P (2015). A generational analysis of consumption patterns and their effects on the wellbeing of indigenous people: A case study of rural KwaZulu-Natal.",

  "5th World Sustainability Forum, 7–9 September 2015, Universitätsspital Basel, Zentrum für Lehre und Forschung, Basel, Switzerland; An international scientific conference of the journal Sustainability under the patronage of the University of Basel.",

  "Msweli, P. (2014). How to change the complexion of the economy from brown to green? Book of Abstracts: Journal of Green Economy and Development 1st Annual Conference, Kloof, Durban 17-19 December.",

  "Olabanji, O. & Msweli, P. (2014). Governance and Macroeconomic determinants of Venture Capital Supply in SubSaharan Africa. Book of Abstracts: Journal of Green Economy and Development 1st Annual Conference, Kloof, Durban 17-19 December.",

  "Semmaw G & Msweli, P. (2014). An analysis of Structures and processes of business ethics across public enterprises in the context of the green economy agenda: The case of Ethiopia. Book of Abstracts: Journal of Green Economy and Development 1st Annual Conference, Kloof, Durban 17-19 December.",

  "Msweli P and P Naude (2014). Factors Contributing to Bank Selection Choices: Generation Theory Perspective. 11th Finance Journal Conference, Durban 14-15 May.",

  "Singh S and Msweli P (2013) Board Independence and Board Decision Quality in South Africa: Evidence from State-Owned Enterprises. P International Journal of Arts & Sciences’ (IJAS) International Conference for Academic Disciplines which will be held at the University of London Union, Malet Street, London, UK, from 5 to 8 November 2013.",

  "Msweli P, Wushe T. and Wedzerai C Mandudzo (2013). Corporate Community Engagement: Literature search for construct validation. A case of the extractive industry in Zimbabwe, IEDRC Jakarta Indonesia, July",

  "Msweli P and Masarira S (2013). Towards the social responsibility measure for small business. 1st  International Conference on Development Finance & Economic Transformation, 27-29 October, Polokwane, South Africa.",

  "Tala L and Msweli P. (2013). The impact of access to credit on household welfare in South Africa. 1st  International Conference on Development Finance & Economic Transformation, 27-29 October, Polokwane, South Africa.",

  "Msweli P, Matjekana K, and Tala L. (2013). Determinants of economics students’ success rate: University of Limpopo case. 1st  International Conference on Development Finance & Economic Transformation, 27-29 October, Polokwane, South Africa.",

  "Mashamaite M and Msweli P (2013). Black Economic Empowerment and the road freight transport system. 1st  International Conference on Development Finance & Economic Transformation, 27-29 October, Polokwane, South Africa.",

  "Msweli, P (2013). 's Human Capital Development the Weakest Link in the Chain of Economic Development Policies in South Africa?' Fifteenth Annual International Conference of the Global Business and Technology Association Helsinki, Finland from July 2nd – 6th.",

  "Masarira S and Msweli P (2013). The Role of SMEs in National Economies: The Case of South Africa. Economic and Social Development 2013, 2nd International Scientific Conference Economic and Social Development 4-5 April 2013 Paris, France.",

  "Singh S and Msweli P (2012). Towards a Board Activism Framework for State Owned Enterprises. 3rd International Conference on Business and Economics (ICBE), 13-15 September, Cape Town.",

  "Msweli, P (2012). Towards a Community Engagement Tuition and Research Framework: A DTI B-BBEE Training Programme Case. University of South Africa Community Engagement Conference.",

  "Msweli, P. (2011). “Mapping an interplay between Open Distance Learning and Internationalisation” Conference on Higher Education in South Africa, Fort Hare, East London, 22-24 November.",

  "Msweli P. (2011). “What exactly is internationalisation? - Validating the content of the internationalisation construct” 1st International Conference on Leadership and Management for Sustainable Development, UNISA Graduate School of Business Leadership (SBL), 6-10 October.",

  "Ngobese, Z., Msweli, P. and Ndlovu, N. (2011). “Millennium Poverty Reduction: A case of KwaZulu-Natal Province.” 23rd Annual South African Institute of Management Science (SAIMS) conference. September 11-14, Durban.",

  "Msweli, P (2011) “Internationalisation in higher education institutions in South Africa: Scale development and measurement” 15th Annual IEASA Conference, Durban, 31 Aug – 3 Sept 2011.",

  "Msweli-Mbanga, P & Mkhize, H (2006) “The impact of globalisation of the performance of the JSE securities exchange of South Africa” at the IABT 2006 Conference held in Mystic Connecticut, USA, (31 May to 3 June).",

  "Tewari D, and Msweli-Mbanga (2006) An econometric model for variables affecting examinations pass/failure marks in Introductory Accounting Course: A case of University of Kwa-Zulu Natal. Paper accepted. Helsa Conference, Pretoria (27-29 November).",

  "Mkhize, H M & Msweli-Mbanga, P (2005) “A critical review of the restructuring of the South African capital market” The 2nd International Business Research Conference, Sydney, Australia (5 – 8 December 2005).",

  "Msweli-Mbanga, P (2005) “The quitting behaviour of generation X employees in the Higher Education Institution: A South African Case” The Fifth International Conference on Knowledge, Culture and Change in Organisations, Rhodes, Greece (19 – 22 July 2005).",

  "Msweli-Mbanga P & Potwana N. (2004) “Integrating Participation with Citizenship Behaviour: A Framework for Reducing Resistance to Change” Global Business, Cape Town",

  "Msweli-Mbanga & Potwana N. (2004) “Extra Role Performance and Participation in the Context of Organisational Change” The 5th European Conference on Knowledge Management, Paris, France (30 Sept-1Oct).",

  "Msweli-Mbanga P. (2003) “Relating Ethical Climate, Performance and Turnover in Direct Selling: Research Propositions and Managerial Implications” Corporate Social Responsibility and Managing Diversity Conference- UCT June.",

  "Msweli-Mbanga P. (2003) “Work Motivation and Social Identification Effects On Performance of Direct Salespeople” Proceedings 15th Annual Conference of the South African Institute for Management Scientists.",

  "Msweli-Mbanga P. (2002) “Direct Selling Distributor Characteristics” Direct Selling World Congress – Toronto Canada, (Sept).",

  "Msweli-Mbanga P. (2002) “Extra-role Performance in the Direct Selling Industry” Proceedings 14th Annual Conference of the South African Institute for Management Scientists, Challenges facing emerging markets in transition, (Sept/Oct).",

  "Msweli Pumela and Adrian Sargeant (1998) “Direct Selling in the Rainbow Nation – Retaining the Distributor” Proceedings of the Multicultural Marketing Conference – Montreal, Canada (Sept).",

  "Sargeant Adrian and Pumela Msweli (1998) Keeping the Distributor Sold – Towards a Model of Retention in Network Marketing Organisations”",

  "Sargeant Adrian and Pumela Msweli (1998) Keeping the Distributor Sold – Towards a Model of Retention in Network Marketing Organisations” Proceedings of the Academy of Marketing Conference, Sheffield UK, (July).",

  "Msweli Pumela (1997) “Distributor Retention in Network Marketing Organisations” Proceedings of Doctoral Colloquium of the Academy of Marketing Conference, Manchester Metropolitan University, UK, (July).",
];

export const ResearchReport = [
  "Msweli P., Ncube M. Mhlanga D. (2016). SARS: Constructing a ‘Attitude to Tax Compliance’ index.",

  "Msweli P, Hutton, T, Tau-Sekati P, Ditodi L, Rwelamila PD, Okharedia AA (2013) Black Economic Empowerment Deals, Department of Trade and Industry.",

  "Msweli P (2012). Broad-Based Black Economic Empowerment: A Critical Review, Näringslivets Internationella Råd International Council of Swedish Industry.",

  "Hutton T and Msweli P (2011). Towards a Standardised National Curriculum for the B-BBEE Verification Analysts, Department of Trade and Industry Steering Committee on B-BBEE Training Programme.",

  "Msweli P and Ngobese NAC (2010). Evaluation of the Cato Manor HIV/AIDS Programme, eThekwini Municipality.",

  "Msweli (2010). Cato Manor ABM Community Health Workers Skills Analysis, eThekwini Municipality.",

  "Msweli P (2009). Investment Promotion Policy & Investment Incentive Strategy. Economic Development, EThekwini Municipality.",

  "Msweli P (2009). Report on Assessment Study on Socio Economic Impact of Empowerment Workshops in the Rural Areas, Business Support and Markets, EThekwini Municipality.",

  "Msweli P (2008).  Impact Assessment Report on SMME Trade Fairs, EThekwini Municipality.",

  "Msweli P (2007). Skills Audit Report of NCV Educators, Esayidi FET College.",

  "Msweli P, and Thabede (2004). Wholesale and Retail Seta – Evaluation of Outsource Model, Pretoria.",

  "Cassim S and Msweli P (2003) Durban Solid Waste Internal and external environment analysis.",

  "Burgess S and Msweli P  (1999) Transformation Strategy for the Gauteng Race Horse Trust, Johannesburg",
];
