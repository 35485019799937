/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Book from "@material-ui/icons/BookOutlined";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import CloudDownload from "@material-ui/icons/CloudDownload.js";
import Button from "components/CustomButtons/Button.js";
import SectionHeaderLinks from "AcademicWritingWebsite/views/AboutUsPage/Sections/SectionHeaderLinks.js";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";
import SectionDescription from "./Sections/SectionDescription";
import {
  BooksAndChapters,
  PeerReviewJournals,
  ConferencePaper,
  ResearchReport,
} from "./Sections/Data.js";

const useStyles = makeStyles(profilePageStyle);

export default function ResearchProfilePage({ ...rest }) {
  console.log(BooksAndChapters);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      <Header
        color="transparent"
        brand=""
        links={<SectionHeaderLinks dropdownHoverColor="info" />}
        fixed
        changeColorOnScroll={{
          height: 300,
          color: "info",
        }}
        {...rest}
      />
      <Parallax
        image={require("assets/img/academicWritingImages/CultivatingWritingCreativity/writing.png")}
        className={classes.parallax}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <SectionDescription />
        <div className={classes.container}>
          <div className={classNames(classes.description, classes.textCenter)}>
            <h2 className={classes.title}>Research Profile: Publications</h2>{" "}
            <Button round href="#pablo" color="info">
              <CloudDownload className={classes.icons} />
              <a
                href="/asserts/Articles/Research Profile PMsweli 2.pages"
                target="_blank"
              >
                Download
              </a>
            </Button>
          </div>
          <div className={classes.profileTabs}>
            <NavPills
              alignCenter
              color="primary"
              tabs={[
                {
                  tabButton: "Books and book chapters",
                  tabIcon: Book,
                  tabContent: (
                    <div>
                      <ol>
                        {BooksAndChapters.map((book) => {
                          return (
                            <li>
                              {book}
                              <hr />
                            </li>
                          );
                        })}
                      </ol>
                    </div>
                  ),
                },
                {
                  tabButton: "Peer Reviewed Journal Articles",
                  tabIcon: Book,
                  tabContent: (
                    <div>
                      <ol>
                        {PeerReviewJournals.map((book) => {
                          return (
                            <li>
                              {book}
                              <hr />
                            </li>
                          );
                        })}
                      </ol>
                    </div>
                  ),
                },
                {
                  tabButton: "Peer Reviewed Conference Paper",
                  tabIcon: Book,
                  tabContent: (
                    <div>
                      <ol>
                        {ConferencePaper.map((book) => {
                          return (
                            <li>
                              {book}
                              <hr />
                            </li>
                          );
                        })}
                      </ol>
                    </div>
                  ),
                },
                {
                  tabButton: "Research Reports",
                  tabIcon: Book,
                  tabContent: (
                    <div>
                      <ol>
                        {ResearchReport.map((book) => {
                          return (
                            <li>
                              {book}
                              <hr />
                            </li>
                          );
                        })}
                      </ol>
                    </div>
                  ),
                },
              ]}
            />
          </div>
          <Clearfix />
        </div>
      </div>
      <Footer
        content={
          <div>
            <div className={classes.left}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.creative-tim.com/?ref=mkpr-profile"
                    target="_blank"
                    className={classes.block}
                  >
                    Creative Tim
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.creative-tim.com/presentation?ref=mkpr-profile"
                    target="_blank"
                    className={classes.block}
                  >
                    About us
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="//blog.creative-tim.com/" className={classes.block}>
                    Blog
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.creative-tim.com/license?ref=mkpr-profile"
                    target="_blank"
                    className={classes.block}
                  >
                    Licenses
                  </a>
                </ListItem>
              </List>
            </div>
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()} , made with{" "}
              <Favorite className={classes.icon} /> by{" "}
              <a
                href="https://www.creative-tim.com?ref=mkpr-profile"
                target="_blank"
              >
                Creative Tim
              </a>{" "}
              for a better web.
            </div>
          </div>
        }
      />
    </div>
  );
}
